// main entry point

$(document).ready(function () {

    SKTV.UI.init();
    SKTV.Slider.init();
    SKTV.SearchPresse.init();

    SKTV.Common.backToTopButton();
    SKTV.Common.initStickySidebar();
    SKTV.Common.removeBlinkingCursor();
    SKTV.Common.initStrikeprice();

    SKTV.Navigation.init();

    SKTV.Newsletter.init();

    SKTV.Promo.init();
    SKTV.Optionsbuchung.init();
    SKTV.Common.initSEOLinks();
    SKTV.Common.initOffersearch();
    SKTV.UserSiteMap.init();

    SKTV.Common.addFramedContentAdditions();
    SKTV.Tealium.init();
});

// wait that everything is loaded
$(window).load(function () {
    SKTV.Tvlive.init();
});

// handle window resize
$(window).resize(function () {

});

$('.mobileNavCaret').click(function () {
  var $dropdown = $(this).next('.dropdown');
  $dropdown.css({
    'transition': 'max-height .5s ease',
    '-webkit-transition': 'max-height .5s ease',
    'overflow': 'hidden'
  });
  $dropdown.css('max-height', $dropdown[0].scrollHeight + 'px');
  if ($dropdown.is(':visible')) {
    $dropdown.css('max-height', $dropdown[0].scrollHeight + 'px');
    setTimeout(function() {
      $dropdown.css('max-height', '0');
    }, 10);
    setTimeout(function() {
      $dropdown.hide();
    }, 500);
  } else {
    $dropdown.show();
    setTimeout(function() {
      $dropdown.css('max-height', $dropdown[0].scrollHeight + 'px');
    }, 10);
  }
});
